class CustomerData {
  constructor(
      {
        name,
        image,
        url,
      }) {
    this.name = name ?? "";
    this.image = image;
    this.url = url ?? "";
  }

  static listCustomerData() {
    // React Native doesn't support requiring images dynamically at run-time.
    // https://stackoverflow.com/questions/71470706/how-to-require-image-in-react-native
    return [
      new CustomerData({
        name: "HOLLA",
        image: require("../../assets/logos/customer/holla.png"),
        url: "https://www.holla.world/",
      }),
      new CustomerData({
        name: "IWEE",
        image: require("../../assets/logos/customer/iwee.png"),
        url: "https://iweelive.com/",
      }),
      new CustomerData({
        name: "KuaiYin",
        image: require("../../assets/logos/customer/kuaiyin.png"),
        url: "https://www.kaixinyf.cn/",
      }),
      new CustomerData({
        name: "MeowMe",
        image: require("../../assets/logos/customer/meowme.png"),
        url: "https://www.tadatt.com/",
      }),
      new CustomerData({
        name: "MONKEY",
        image: require("../../assets/logos/customer/monkey.png"),
        url: "https://www.monkey.app/",
      }),
      new CustomerData({
        name: "OMI",
        image: require("../../assets/logos/customer/omi.png"),
        url: "https://omi.sg/",
      }),
      new CustomerData({
        name: "QianShou",
        image: require("../../assets/logos/customer/qianshou.png"),
        url: "https://qianshouapp.cn/",
      }),
      new CustomerData({
        name: "TieTie",
        image: require("../../assets/logos/customer/tietie.png"),
        url: "https://www.tie520.com/",
      }),
      new CustomerData({
        name: "YiDui",
        image: require("../../assets/logos/customer/yidui.png"),
        url: "https://520yidui.com/",
      }),
    ];
  }
}

export default CustomerData;