import React from "react";
import {FlatList, Image, SafeAreaView, StyleSheet, View} from "react-native";
import {Flex} from "@react-native-material/core";
import {px} from "csx";
import CustomerData from "./data/customer_data";

const Logo = ({path, url, name}) => (
    <Flex>
      <View style={styles.centerItem}>
        <Image
            source={path}
            href={url}
            style={styles.logo}
        />
        <View style={{height: 12}}/>
        <text>
          {name}
        </text>
      </View>
    </Flex>
);

const CustomerLogos = () => (
    <SafeAreaView>
      <h2 style={styles.title}>
        Meet Our Customers
      </h2>
      <View style={styles.list}>
        <FlatList
            data={CustomerData.listCustomerData()}
            horizontal={true}
            renderItem={
              ({item}) =>
                  <Logo path={item.image} url={item.url} name={item.name}/>
            }
        />
      </View>
    </SafeAreaView>
);

const styles = StyleSheet.create({
  logo: {
    width: px(64),
    height: px(64),
    borderRadius: 8,
    marginHorizontal: px(10),
  },
  title: {
    textAlign: "center",
  },
  list: {
    marginTop: px(48),
    marginBottom: px(48),
  },
  centerItem: {
    alignItems: "center",
  },
});

export default CustomerLogos;
